import React, { useState } from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { ButtonPrimary } from "../../components/ButtonPrimary/ButtonPrimary"
import ArrangeDialog from "../../components/ArrangeDialog/ArrangeDialog"
import InformationDialog from "../../components/ArrangeDialog/InformationDialog/InformationDialog"
import AfterSendEmailDialog from "../../components/ArrangeDialog/AfterSendEmailDialog/AfterSendEmailDialog"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useIntl } from "gatsby-plugin-intl"

const StyledAniLink = styled(AniLink)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
`
const StyledWrapper = styled.section`
  position: relative;
  padding-bottom: 100px;
  max-width: 1500px;
  margin: 150px auto 0;
`

const Cloud = styled(Image)`
  position: absolute !important;
  top: 2%;
  left: 2%;
  width: 60%;
  max-width: 428px;

  @media only screen and (min-width: 736px) {
    width: 40%;
  }
  @media only screen and (min-width: 1024px) {
    width: 30%;
  }
`
const CloudMedium = styled(Image)`
  position: absolute !important;
  top: -2%;
  left: 10%;
  width: 80%;
  max-width: 674px;

  @media only screen and (min-width: 736px) {
    width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    width: 80%;
  }
`

const StyledImage = styled(Image)`
  width: 80%;
  height: 30vw;
  max-height: 300px;
  max-width: 1300px;
  margin: 0 auto;

  img {
    object-position: ${({ page }) =>
      page === "banners" ? "center top !important" : "inherit"};
  }
`

const StyledTypography = styled(Typography)`
  text-align: center;
  margin: 40px 0 20px;
  font-weight: 600;
  font-size: 2.5rem;
  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`

const StyledSubtitle = styled(Typography)`
  text-align: center;
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 0.8rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.25rem;
  }
`

const StyledDescription = styled(Typography)`
  text-indent: 40px;
  width: 85%;
  max-width: 800px;
  margin: 40px auto 0;
  font-size: 0.9rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.1rem;
  }
`
const StyledDescription2 = styled(Typography)`
  display: ${({ page }) => (page === "flights" ? "block" : "flex")};
  align-items: center;
  justify-content: center;

  a {
    text-align: center;
    font-size: 20px;
  }
  span {
    font-size: 40px;
  }
  width: 85%;
  max-width: 800px;
  margin: 40px auto 0;
  font-size: 0.9rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.1rem;
  }
`

const ArticleImage = styled(Image)`
  width: 60%;
  max-height: 300px;
  max-width: 600px;
  margin: 80px auto;
`

const InnerWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
`

const StyledGrid = styled(Grid)`
  position: relative;
  display: flex;
  margin: 60px auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  a {
    order: 1;
  }

  @media only screen and (min-width: 568px) {
    flex-direction: row;
    button {
      order: 2;
    }
  }
`
const SubpageSheet = props => {
  const {
    title,
    subtitle,
    heroImage,
    cloudSmall,
    cloudMedium,
    anotherImage,
    description,
    description2,
    page,
  } = props

  const { locale, formatMessage } = useIntl()

  const [arrangeDialogOpen, setArrangeOpen] = useState(false)
  const [informationDialogOpen, setInformationOpen] = useState(false)
  const [emailSendDialogOpen, setEmailSendOpen] = useState(false)
  const [isSendError, setSendError] = useState(false)
  const handleOpen = event => {
    event.preventDefault()
    setArrangeOpen(true)
    setInformationOpen(true)
  }
  const handleClose = () => {
    setArrangeOpen(false)
  }
  const handleCloseInformationDialog = () => {
    setInformationOpen(false)
  }
  return (
    <StyledWrapper>
      <CloudMedium fluid={cloudMedium} />
      <Cloud fluid={cloudSmall} />
      <InnerWrapper>
        <StyledTypography variant="h3">{title}</StyledTypography>
        <StyledSubtitle variant="h6" component="p">
          {subtitle}
        </StyledSubtitle>
        <StyledImage fluid={heroImage} page={page} />
        <StyledDescription variant="subtitle1" component="p">
          {description()}
        </StyledDescription>
        <ArticleImage fluid={anotherImage} />

        {page === "skydiving" || page === "flights" ? (
          <StyledDescription2 variant="subtitle1" component="p" page={page}>
            {description2()}
          </StyledDescription2>
        ) : (
          <StyledDescription variant="subtitle1" component="p">
            {description2}
          </StyledDescription>
        )}

        <StyledGrid item container xs={9}>
          <AniLink
            paintDrip
            duration={1}
            hex="#252a36"
            to={`/${locale}/`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowRightAltIcon style={{ transform: "rotate(180deg)" }} />
            {formatMessage({ id: "Back to home page" })}
          </AniLink>

          <ButtonPrimary
            className="main"
            variant="contained"
            size="large"
            endIcon={
              page === "banners" ||
              page === "selling" ||
              page === "skydiving" ? null : (
                <ArrowRightAltIcon />
              )
            }
            main="true"
            onClick={handleOpen}
            style={{
              position: "relative",
              visibility: "visible",
              margin: "30px 0",
              display: "flex",
            }}
          >
            {page === "banners" ||
            page === "selling" ||
            page === "skydiving" ? (
              <StyledAniLink
                paintDrip
                duration={1}
                hex="#252a36"
                to={`/${locale}/contact`}
              >
                {formatMessage({ id: "Contact" })}

                <ArrowRightAltIcon />
              </StyledAniLink>
            ) : (
              formatMessage({ id: "Book a flight" })
            )}
          </ButtonPrimary>
        </StyledGrid>

        {page === "banners" ||
        page === "selling" ||
        page === "skydiving" ? null : (
          <>
            <ArrangeDialog
              arrangeDialogOpen={arrangeDialogOpen}
              handleClose={handleClose}
              setEmailSendOpen={setEmailSendOpen}
              setSendError={setSendError}
            />
            <InformationDialog
              informationDialogOpen={informationDialogOpen}
              handleCloseInformationDialog={handleCloseInformationDialog}
              setInformationOpen={setInformationOpen}
            />
            <AfterSendEmailDialog
              setEmailSendOpen={setEmailSendOpen}
              emailSendDialogOpen={emailSendDialogOpen}
              isSendError={isSendError}
            />
          </>
        )}
      </InnerWrapper>
    </StyledWrapper>
  )
}

export default SubpageSheet
